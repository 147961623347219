/* ############### SignForm Section ############### */

.sign-form-wrapper {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 5px;
  width: 100%;
  margin: 10px auto;
  max-width: 450px;
  padding: 30px 40px 30px;
  position: relative; 
  z-index: 2;
}

.header-wrapper-home::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

@media (max-width: 550px) {
  .sign-form-wrapper {
    padding: 40px 20px 40px;
    min-height: 500px;
  }
}

.sign-form-title {
  color: #fff;
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 28px;
}

.sign-form-error {
  background: #e87c03;
  border-radius: 4px;
  font-size: 14px;
  margin: 0 0 16px;
  color: white;
  padding: 15px 20px;
}

.sign-form-base {
  display: flex;
  flex-direction: column;
  max-width: 450px;
  width: 100%;
}

.sign-form-input {
  background: #333;
  border-radius: 4px;
  border: 0;
  color: #fff;
  height: 50px;
  line-height: 50px;
  padding: 5px 20px;
  margin-bottom: 20px;
}

.sign-form-Button {
  background: #cb4934;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 12px;
  padding: 16px;
  border: 0;
  color: white;
  cursor: pointer;
}

.sign-form-text {
  color: #8c8c8c;
  font-size: 16px;
  font-weight: 500;
}

.sign-form-link {
  color: #fff;
  text-decoration: none;
  margin-right: 30px;
  cursor: pointer;
  text-decoration: none;
  margin-left: 5px;
}

.sign-form-link:hover {
  text-decoration: underline;
}

.sign-form-captcha {
  margin-top: 10px;
  font-size: 13px;
  line-height: normal;
  color: #8c8c8c;
}
