/* ############### Jumbotron Section ############### */

.jumbo-imagewrapper {
  width: 55%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 950px) {
  .jumbo-imagewrapper {
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
  }
}

.jumbo-textwrapper {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 950px) {
  .jumbo-textwrapper {
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
  }
}

.jumbo-item {
  padding: 45px 0%;
  max-width: 1100px;
  margin: auto;
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 950px) {
  .jumbo-item {
    flex-direction: column;
  }
}

.jumbo-title {
  margin-top: 0px;
  font-size: 2.3rem;
  line-height: 1.1;
  margin-bottom: 8px;
}

@media (max-width: 950px) {
  .jumbo-title {
    font-size: 2.5rem;
    text-align: center;
    padding-right: initial;
  }
}

@media (max-width: 550px) {
  .jumbo-title {
    font-size: 1.8rem;
    text-align: center;
  }
}

.jumbo-subtitle {
  font-size: 1.5rem;
  font-weight: normal;
  line-height: normal;
}

@media (max-width: 950px) {
  .jumbo-subtitle {
    font-size: 1.25rem;
    text-align: center;
    padding-right: initial;
  }
}

@media (max-width: 550px) {
  .jumbo-subtitle {
    font-size: 1.5rem;
    text-align: justify;
  }
}

@media (max-width: 950px) {
  .jumbo-imagewrapper {
    text-align: center;
  }
}

.jumbo-image {
  max-width: 100%;
  height: auto;
}
