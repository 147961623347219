/* ############### Header Section ############### */

.header-wrapper-home {
  background: url("/images/misc/fondo.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 30px 50px;
  position: relative;
  height: 100vh;
}

@media screen and (max-width: 550px) {
  .header-wrapper-home {
    height: auto;
    padding: 10px;
  }
}

.header-wrapper-home::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.header-wrapper-home::after {
  content: "";
  display: table;
  clear: both;
}


.header-wrapper-home-signup {
  background: url("/images/misc/fondo.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 30px 50px;
  position: relative;
  height: 100vh;
}

@media screen and (max-width: 550px) {
  .header-wrapper-home-signup {
    padding: 10px;
  }
}

.header-wrapper-home-signup::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.header-wrapper-home-signup::after {
  content: "";
  display: table;
  clear: both;
}

.navbar-home,
.feature-wrapper-home {
  position: relative;
  /*  z-index: 1; */
}

.button {
  position: relative;
  user-select: none;
  border: none;
  outline: none;
  color: rgb(255 255 255);
  text-transform: uppercase;
  font-weight: 700;
  padding: 0.75rem 1.5rem;
  background-color: #47205d;
  border-radius: 0.5rem;
  width: 90%;
  text-shadow: 0px 4px 18px #2c3442;
}

.btn {
  cursor: pointer;
  font-size: 20px;
  width: auto;
  background: linear-gradient(90deg, #cb4934, #71209f);
  /*  z-index: 1; */
}

.btn-new {
  cursor: pointer;
  font-size: 20px;
  width: 25%;
  background: #cb4934;
  ;
}

@media screen and (max-width: 550px) {
  .btn {
    font-size: 16px !important;
    width: auto !important;
  }
}


@media screen and (max-width: 850px) {
  .btn {
    font-size: 13px;
    width: 40%;
  }
}

@media screen and (max-width: 550px) {
  .btn-new {
    font-size: 20px !important;
    width: auto !important;
  }
}


@media screen and (max-width: 850px) {
  .btn-new {
    font-size: 13px;
    width: 40%;
  }
}

.btn-container {
  display: flex;
  justify-content: center;
  padding-top: 40px;
}

@media screen and (max-width: 550px) {
  .btn-container {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}


.header-wrapper-browse {
  background: url("/images/misc/joker1.jpg");
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 30px 50px;
}

@media (max-width: 550px) {

  .header-wrapper-home,
  .header-wrapper-browse {
    padding: 15px 20px;
  }
}

.navbar-home,
.navbar-signin {
  max-width: 1850px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-right: auto;
  margin-left: auto;
}

.navbar-signin {
  margin-bottom: 0px;
  position: relative;
  z-index: 10;
}

@media (max-width: 550px) {

  .navbar-home,
  .navbar-signin {
    margin-bottom: 70px;
  }
}

.navbar-browse {
  max-width: 1850px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 175px;
  margin-right: auto;
  margin-left: auto;
}

.logo {
  height: auto;
  width: 20%;
}

@media (max-width: 550px) {
  .logo {
    height: auto;
    width: 35%;
  }
}

@media (max-width: 768px) {
  .logo-container {
    display: flex;
    align-items: center;
    justify-content: left;
    height: 100%;
  }
}



.signin-button {
  display: block;
  background-color: #e50914;
  width: 84px;
  height: fit-content;
  color: #fff;
  border: 0;
  font-size: 15px;
  border-radius: 3px;
  padding: 8px 17px;
  cursor: pointer;
}

.header-link-container {
  display: flex;
  align-items: center;
}

.menu-icon {
  display: none;
  font-size: 30px;
  cursor: pointer;
  color: #fff;
}

.header-link {
  display: flex;
  flex-direction: row;
  padding: 10px;
}

.header-link a {
  text-shadow: 0 0 30px #000;
  color: #fff;
  margin-left: 25px;
  cursor: pointer;
}

.header-link-bold {
  font-weight: bolder;
}

@media (max-width: 768px) {
  .menu-icon {
    display: block;
  }

  .header-link {
    display: none;
    flex-direction: column;
    width: 100%;
    background: #c44835;
    position: absolute;
    top: 50px;
    left: 0;
    z-index: 1;
    border-radius: 10px;
  }

  .header-link.open {
    display: flex;
  }

  .header-link a {
    border-radius: 10px;
    padding: 10px;
    margin: 5px;
    border: 1px solid #fff;
  }
}