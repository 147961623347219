/* ############### Accordion Section ############### */

.accordion-wrapper {
  max-width: 900px;
  margin: auto;
  padding: 50px 45px;
  display: flex;
  flex-direction: column;
}

@media (max-width: 550px) {
  .accordion-wrapper {
    max-width: initial;
    padding: 50px 20px;
  }
}

.accordion-title {
  font-size: 50px;
  line-height: 1.1;
  margin-top: 0;
  margin-bottom: 50px;
  color: #fff;
  text-align: center;
}

@media (max-width: 550px) {
  .accordion-title {
    font-size: 25px;
  }
}

.accordion-item {
  margin-bottom: 10px;
}

.accordion-header {
  border-radius: 25px;
  background-color: #6d00af;
  font-size: 23px;
  font-weight: normal;
  padding: 0.7em 1.2em 0.7em 1.2em;
  margin-bottom: 1px;
  cursor: pointer;
  display: flex; /* Usa flexbox para alinear verticalmente */
  align-items: center;
}

@media (max-width: 550px) {
  .accordion-header {
    font-size: 20px;
  }
}

.accordion-body {
  margin-top: 5px;
  border-radius: 25px;
  background-color: #6d00af;
  font-size: 23px;
  font-weight: normal;
  padding: 0.8em 1.2em 0.8em 1.2em;
}

@media (max-width: 550px) {
  .accordion-body {
    font-size: 20px;
  }
}

.accordion-image {
  filter: brightness(0) invert(1);
  width: 18px;
  margin-left: auto; 
}

@media (max-width: 550px) {
  .accordion-image {
    width: 16px;
  }
}